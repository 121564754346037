import React from 'react'
import Layout from '../components/Layout'
import Section from '../components/common/Section'

const NotFoundPage = () => (
  <Layout>
    <Section height="100vh">
      <h1>No existe esta ruta</h1>
      <p>Lo sentimos.</p>
    </Section>
  </Layout>
)

export default NotFoundPage
